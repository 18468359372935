import Image from '@/common/image';
interface Props {
    className?: string;
    rowReverse?: boolean;
    title?: string;
    description?: string;
    Img1?: string;
    Img2?: string;
}
function CompareFacesDescription(props: Props) {
    return (
        <div
            className={`flex  flex-col-reverse xl:flex-row items-center mt-[64px] md:mb-[100px] ${
                props.className
            } ${props.rowReverse && 'xl:flex-row-reverse'}`}
        >
            <div className="w-full 2xl:w-[70%]">
                <div className={`${props.rowReverse ? ' xl:pl-[96px] pl-0' : 'xl:pr-[96px]'}`}>
                    <p className="pb-8 DH2MH3 text-primary">{props.title}</p>
                    <p className=" lg:pb-10 DP1MP1 md:pb-5">{props.description}</p>
                </div>
            </div>
            <div className="flex justify-center w-full mb-8 2xl:mb-0 2xl:w-[600px]">
                <div className="w-[50%] max-w-[340px] lg:w-[300px] pr-[8px]">
                    <Image
                        className="rounded-[250px] border-redbg border-[3px] max-h-[490px]"
                        filename={props.Img1}
                        alt={props.Img1}
                    />
                </div>
                <div className="w-[50%] max-w-[340px] lg:w-[300px] pl-[8px]">
                    <Image
                        filename={props.Img2}
                        alt={props.Img2}
                        className="border-redbg border-[3px] rounded-[250px] max-h-[490px]"
                    />
                </div>
            </div>
        </div>
    );
}
export default CompareFacesDescription;
