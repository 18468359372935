import { AltLayout } from '../layouts/AltLayout';
import CompareFaces from '@/common/compare-teeth-row';
import { SEO } from '@/common/seo';

const Gallery: Page = () => (
    <AltLayout
        heroImg="GalleryHero.jpg"
        heroButtonText="Get In Touch With Us"
        label="Gallery"
        title="Success Stories"
        description="Lets have a look at a few of the success stories of our patients who came with problems and left satisfied"
    >
        <div className="mt-[120px]">
            <CompareFaces
                rowReverse
                Img1="SuccessStory1a.jpg"
                Img2="SuccessStory1b.jpg"
                title="Success Story #1"
                description="Check out how we transformed this gentleman’s smile!"
            />
            <CompareFaces
                title="Success Story #2"
                Img1="SuccessStory2a.jpg"
                Img2="SuccessStory2b.jpg"
                description="And now look at another happy customer. Can you tell the difference?"
            />
            <CompareFaces
                rowReverse
                title="Success Story #3"
                Img1="SuccessStory3a.jpg"
                Img2="SuccessStory3b.jpg"
                description="This man is a happy man. He is smiling from ear to ear!"
            />
            <CompareFaces
                title="Success Story #4"
                Img1="SuccessStory4a.jpg"
                Img2="SuccessStory4b.jpg"
                description="What a transformation. One word – Gorgeous!"
            />
            <CompareFaces
                rowReverse
                title="Success Story #5"
                Img1="SuccessStory5a.jpg"
                Img2="SuccessStory5b.jpg"
                description="Better than he expected. Show off that smile now!"
            />
            <CompareFaces
                title="Success Story #6"
                Img1="SuccessStory6a.jpg"
                Img2="SuccessStory6b.jpg"
                description="What a confidence builder. He knows it!"
            />
        </div>
    </AltLayout>
);

export default Gallery;

export const Head = () => (
    <SEO
        title="Success Stories Zapata Dental - Zapata Dental"
        description="Dr. Zapata firmly believes that superior quality and patient satisfaction are the fundamentals of excellence in dentistry."
        pathname="/success-stories/"
    />
);
